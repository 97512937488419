function alphaOnly(evt) {
	var theEvent = evt || window.event;
	var key = theEvent.keyCode || theEvent.which;
	key = String.fromCharCode(key);
	var regex = /[a-zA-ZğüşıöçĞÜŞİÖÇ ]|\./;

	if (!regex.test(key)) {
		theEvent.returnValue = false;
		if (theEvent.preventDefault) theEvent.preventDefault();
	}
}

function numOnly(evt) {
	var theEvent = evt || window.event; // Handle paste
	if (theEvent.type === 'paste') {
		key = event.clipboardData.getData('text/plain');
	} else {
		// Handle key press
		var key = theEvent.keyCode || theEvent.which;
		key = String.fromCharCode(key);
	}

	var regex = /[0-9]|\./;

	if (!regex.test(key)) {
		theEvent.returnValue = false;
		if (theEvent.preventDefault) theEvent.preventDefault();
	}
}


var onloadCallback = function onloadCallback() {
	grecaptcha.render("g-recaptcha", {
		'sitekey': '6LeoPVQUAAAAADrcwjYAN-wKb_QRlVpktG2oW3di',
		'callback': verifyCallback
	});
};

var checkAllInputs = () => {
	var inputs = document.querySelector('[data-form]').querySelectorAll('input, select, textarea');
	let result = []
	inputs.forEach(input => {
		let check = input.checkValidity()
		if (check == false) result.push(true)
		//return check == false;
	});

	if (result.length > 0) {
		document.querySelector("[data-form-button]").setAttribute("disabled", "disabled");
	} else {
		document.querySelector("[data-form-button]").removeAttribute("disabled");
	}
}

var verifyCallback = function verifyCallback(response) {
	let isEmpty = () => document.querySelectorAll('.-empty');
	let validForm = isEmpty();

	if (validForm.length === 0) {
		document.querySelector("[step-button]").removeAttribute('disabled');
	} else document.querySelector("[step-button]").setAttribute('disabled', 'disabled');

	return document.querySelector("[data-form-button]").removeAttribute("disabled");
};

var form = function form(_form, newoptions) {
	// default options list
	var options = {
		captcha: false,
		captchaKey: false,
		focus: true,
		class: '-invalid',
		parent: true,
		parentClass: '-parent-invalid' // default message list

	};
	var defaultMessage = [{
		type: "badInput",
		content: [{
			lang: "tr",
			message: "Hatalı girdi tespit edildi."
		}, {
			lang: "en",
			message: "Bad input detected."
		}]
	}, {
		type: "rangeOverflow",
		content: [{
			lang: "tr",
			message: "Sayı kabul edilebilir aralığın üzerinde."
		}, {
			lang: "en",
			message: "The number is greater than an acceptable range."
		}]
	}, {
		type: "rangeUnderflow",
		content: [{
			lang: "tr",
			message: "Sayı kabul edilebilir aralığın altında."
		}, {
			lang: "en",
			message: "The number is less than an acceptable range."
		}]
	}, {
		type: "stepMismatch",
		content: [{
			lang: "tr",
			message: "Girilen numara, belirlenen şekilde bölünemez."
		}, {
			lang: "en",
			message: "The entered number is not divisible by designated."
		}]
	}, {
		type: "typeMismatch",
		content: [{
			lang: "tr",
			message: "Bu alan gerekli girdi tipiyle eşleşmiyor."
		}, {
			lang: "en",
			message: "This field doesn't match the required input type."
		}]
	}, {
		type: "valueMissing",
		content: [{
			lang: "tr",
			message: "Bu zorunlu alan doldurulmalıdır."
		}, {
			lang: "en",
			message: "This mandatory field cannot be left empty."
		}]
	}, {
		type: "tooLong",
		content: [{
			lang: "tr",
			message: "Maksimum {number} karakterli olmalıdır."
		}, {
			lang: "en",
			message: "Must be a maximum of {number} characters."
		}]
	}, {
		type: "tooShort",
		content: [{
			lang: "tr",
			message: "Minimum {number} karakterli olmalıdır."
		}, {
			lang: "en",
			message: "Must be a minimum of {number} characters."
		}]
	}, {
		type: "patternMismatch",
		content: [{
			lang: "tr",
			message: "Lütfen istenen biçimi eşleştirin."
		}, {
			lang: "en",
			message: "Please match the requested format."
		}]
	}]; // validation state list

	var validityState = ['badInput', 'rangeOverflow', 'rangeUnderflow', 'stepMismatch', 'typeMismatch', 'valueMissing', 'tooLong', 'tooShort', 'patternMismatch']; // options update

	var setOptions = function setOptions(defaultOptions, newOptions) {
		for (var item in defaultOptions) {
			if (newOptions[item] === undefined) newOptions[item] = defaultOptions[item];
		}

		return newOptions;
	};

	var addClass = function addClass(el) {
		if (el.type === "checkbox") {
			el.nextElementSibling.classList.add(options.class);
		} else {
			el.classList.add(options.class);
		}

		if (options.parent) {
			el.parentElement.classList.add(options.parentClass);
		} else {
			var a = el.nextSibling;
			a.classList.add(options.class);
		}

		messageDisplay(el);
	};

	var removeClass = function removeClass(el) {
		if (el.type === "checkbox") {
			el.nextElementSibling.classList.remove(options.class);
		} else {
			el.classList.remove(options.class);
		}

		if (options.parent) {
			el.parentElement.classList.remove(options.parentClass);
		} else {
			el.classList.remove(options.class);
		}

		messageDisplay(el);
	};

	var validation = function validation(inputs) {
		inputs.forEach(function (input) {
			validationToggle(input);
			focus(input);
		});
	}; // validation toggle class and message init


	var validationToggle = function validationToggle(input) {
		input.addEventListener("invalid", function (e) {
			e.preventDefault();
			addClass(input);
		}, true);
		input.addEventListener("input", function () {
			if (input.validity.valid) {
				removeClass(input);
			}
		});
	};

	var isAttr = function isAttr(item, attr) {
		return item.hasAttribute(attr) && item.getAttribute(attr) != "";
	}; // language control


	var language = function language() {
		return _form.getAttribute("lang");
	}; // when there is now specific message


	var getDefaultMessage = function getDefaultMessage(input, attr) {
		var item = defaultMessage.find(function (item) {
			return item.type == attr;
		});
		var message = item.content.find(function (item) {
			return item.lang == language();
		});
		return messageReplace(input, attr, message.message);
	}; // input minLength and maxLength validation control


	var messageReplace = function messageReplace(input, attr, message) {
		if (attr == 'tooShort') {
			var minlength = input.getAttribute("minlength");
			return message.replace("{number}", minlength);
		} else if (attr == 'tooLong') {
			var maxlength = input.getAttribute("maxlength");
			return message.replace("{number}", maxlength);
		} else {
			return message;
		}
	}; // validation message control


	var messageDisplay = function messageDisplay(input) {
		var validity = input.validity;
		validityState.forEach(function (item) {
			var attr = "data-".concat(item);

			if (input.type != "file") {
				if (validity[item]) {
					if (isAttr(input, attr)) {
						if (input.type === "checkbox") {
							input.nextElementSibling.nextElementSibling.innerHTML = input.getAttribute(attr);
						} else {
							input.nextElementSibling.innerHTML = input.getAttribute(attr);
						}
					} else {
						input.nextElementSibling.innerHTML = getDefaultMessage(input, item);
					}
				}
			}
		});
	}; // validation focusOut control


	var focusToggle = function focusToggle(input) {
		if (input.validity.valid) {
			removeClass(input);
		} else {
			addClass(input);
		}
	}; // input focus validation control


	var focus = function focus(input) {
		if (options.focus) {
			input.addEventListener('focusout', function () {
				focusToggle(input);
			});
		}
	}; // form validation init


	var phoneMask = document.querySelector('[phone-mask]');
	if (phoneMask) {

		var mask = IMask(
			phoneMask, {
			mask: '\\0(000) 000 00 00'
		}
		);

		var formElem = document.querySelector('form');
		var formLang = formElem.getAttribute('lang');
		var phoneValidFunc = () => {
			phoneMask.addEventListener("input", function () {
				if (mask.value.length != 16) {
					phoneMask.style.border = "1px solid #f00";
					phoneMask.nextElementSibling.style.opacity = "1";
					phoneMask.nextElementSibling.style.display = "flex";
					phoneMask.nextElementSibling.style.visibility = "visible";
					if (formLang == "tr") {
						phoneMask.nextElementSibling.innerHTML = "Eksik değer girdiniz!";
					} else {
						phoneMask.nextElementSibling.innerHTML = "You entered a missing value!";
					}
				} else {
					phoneMask.style.border = "0";
					phoneMask.nextElementSibling.style.opacity = "0";
					phoneMask.nextElementSibling.style.display = "none";
					phoneMask.nextElementSibling.style.visibility = "hidden";
				}
			});
		}
		phoneValidFunc();
	}

	let amount = document.querySelector('[data-amount]');
	let cardNumber = document.querySelector('[data-cart-number]');
	let cardMonth = document.querySelector('[data-cart-month]');
	let cardYear = document.querySelector('[data-cart-year]');
	let cardCvv = document.querySelector('[data-cart-cvv]');

	let amountMask = new IMask(amount, {
		mask: 'num',
		blocks: {
			num: {
				mask: Number,
				thousandsSeparator: ',',
				scale: 2,  // digits after point, 0 for integers
				signed: false,  // disallow negative
				radix: '.',  // fractional delimiter
				mapToRadix: ['.']  // symbols to process as radix
			}
		}
	});

	let cardMonthMask = new IMask(cardMonth, {
		mask: '00',
	});

	let cardYearMask = new IMask(cardYear, {
		mask: '0000',
	});

	let cardCvvMask = new IMask(cardCvv, {
		mask: '000',
	});

	let cardnumber_mask = new IMask(cardNumber, {
		mask: [
			{
				mask: '0000 0000 0000 0000',
			}
		],
	});

	var init = function init() {
		/*
		 * form element check
		 */
		if (!_form || _form.tagName.toLowerCase() != 'form') throw new Error('Error: Form not found!');
		/*
		 * options value update
		 */

		options = setOptions(options, newoptions);
		/*
		 * form validation control
		 */

		var inputs = _form.querySelectorAll('input, select, textarea');

		validation(inputs);
	};
	/*
	 * form init
	 */


	init();

};